import React, { ReactNode } from "react";
import { useGetCommonConfigs, useAnonSession } from "hooks";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

type GlobalHooksProviderProps = {
  children: ReactNode;
};

export const GlobalHooksProvider: React.FC<GlobalHooksProviderProps> = ({ children }) => {
  // eslint-disable-next-line
  console.log(WalletAdapterNetwork.Mainnet);
    // eslint-disable-next-line
    console.log(WalletAdapterNetwork.Devnet);
  useGetCommonConfigs();
  useAnonSession();
  return <>{children}</>;
};
