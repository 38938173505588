(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("survey-creator-core"));
	else if(typeof define === 'function' && define.amd)
		define("[dashedname]", ["survey-creator-core"], factory);
	else if(typeof exports === 'object')
		exports["[dashedname]"] = factory(require("survey-creator-core"));
	else
		root["SurveyCreatorLocales"] = factory(root["SurveyCreatorCore"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_survey_creator_core__) => {
return 