import { CHOOSE_PAYMENT_COMPONENT_TYPES } from "./types";

export const CONTENT = {
  [CHOOSE_PAYMENT_COMPONENT_TYPES.PLAN]: {
    header: "Upgrade your plan",
    subheader: "What is your favorite payment method to upgrade your plan?",
  },
  [CHOOSE_PAYMENT_COMPONENT_TYPES.SURVEY]: {
    header: "Fund your survey",
    subheader: "What is your favorite payment method to fund your survey?",
  },
};
