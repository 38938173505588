import { useState, useEffect } from "react";
import { IExecDataProtector } from "@iexec/dataprotector";
import { useSwitchChain, useChainId, useAccount } from "wagmi";
import { EIP1193Provider } from "viem";

import type { AddressOrENS } from "@iexec/dataprotector";

export const useWeb3Mail = () => {
  const [dataProtector, setDataProtector] = useState<IExecDataProtector | null>(null);
  const chainId = useChainId();
  const { isConnected, connector } = useAccount();
  const { switchChainAsync } = useSwitchChain();

  useEffect(() => {
    init();
  }, [isConnected, connector, chainId]);

  const init = async () => {
    if (connector) {
      const provider = (await connector.getProvider()) as EIP1193Provider;
      const dataProtector = new IExecDataProtector(provider);
      setDataProtector(dataProtector);
    } else {
      setDataProtector(null);
    }
  };

  const changeNetwork = async () => {
    if (chainId !== 134) {
      await switchChainAsync({ chainId: 134 });
    }
    return true;
  };

  const protectData = async (email: string) => {
    if (!dataProtector) {
      throw new Error("Data protector is not initialized");
    }

    if (chainId !== 134) {
      throw new Error("Chain Id is not configured");
    }

    const protectedData = await dataProtector.protectData({
      data: {
        name: "QSTN Protected Data",
        email: email,
      },
    });

    return protectedData;
  };

  const grantAccess = async (protectedDataParam: string) => {
    if (!dataProtector) {
      throw new Error("Data protector is not initialized");
    }

    if (chainId !== 134) {
      throw new Error("Chain Id is not configured");
    }

    const protectedData = protectedDataParam as unknown as AddressOrENS;
    const authorizedUser = process.env.REACT_APP_EVM_MANAGER as unknown as AddressOrENS;
    const authorizedApp = "web3mail.apps.iexec.eth" as unknown as AddressOrENS;

    const grantAccessResult = await dataProtector.grantAccess({
      protectedData,
      authorizedUser,
      authorizedApp,
      numberOfAccess: 999999,
    });

    return grantAccessResult;
  };

  return { isDataProtectorInitialized: Boolean(dataProtector), protectData, grantAccess, changeNetwork };
};
