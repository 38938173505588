import { rootstockTestnet, rootstock, kakarotSepolia } from "wagmi/chains";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { bellecourChain } from "./web3-mail-chain";

export const wagmiConfig = getDefaultConfig({
  appName: "QSTN",
  projectId: process.env.REACT_APP_WAGMI_PROJECT_ID ?? "",
  chains: [process.env.REACT_APP_ENV === "development" ? rootstockTestnet : rootstock, bellecourChain, kakarotSepolia],
  ssr: true,
});
