const tokensLogo = {
  MATIC: "/images/chain_images/polygon_chain.webp",
  ANSR: "/images/ansr-logo.webp",
  TON: "/images/toncoin-icon.webp",
  NFT: "/images/NFT.svg",
  SOL: "/images/chain_images/SOL.svg",
  RBTC: "/images/RBTC-logo.webp",
  NEAR: "/images/near-coin.svg",
  ETH: "/images/kakarot-logo.webp",
  USD: "/images/usd-logo.svg",
};

export { tokensLogo };
