import { scales } from "./types";

export const scalesStyles = {
  [scales.XS]: "w-6 h-6",
  [scales.SM]: "w-8 h-8",
  [scales.MD]: "w-10 h-10",
  [scales.LG]: "w-12 h-12",
  [scales.XL]: "w-32 h-32",
  [scales.XXL]: "w-40 h-40",
};

export const scalesTextStyles = {
  [scales.XS]: "text-xs",
  [scales.SM]: "text-sm",
  [scales.MD]: "text-base",
  [scales.LG]: "text-lg",
  [scales.XL]: "text-3xl",
  [scales.XXL]: "text-6xl",
};
