export const themeColors = {
  dark: {
    headerNavActive: "#27272A",
    input: "#1f1f22",
    surveycard: "#1f1f22",
    icon: "#3F3F46",
    body: "#18181B",
    text: "#ffffff",
    text2: "#EDEDED",
    grey: "#A1A1AA",
    cinc: "#A1A1AA",
    button: "var(--zinc-850, #1F1F22)",
    buttonText: "#FFFFFF",
    base0: "#000000",
  },
  light: {
    headerNavActive: "#E4E4E7",
    input: "#FFFFFF",
    surveycard: "#D4D4D8",
    icon: "#D4D4D8",
    body: "#EDEDED",
    text: "#000000",
    text2: "#27272A",
    grey: "#000000",
    cinc: "#52525B",
    button: "#FFFFFF",
    buttonText: "var(--zinc-950, #09090B)",
    base0: "#FFFFFF",
  },
};
